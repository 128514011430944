import Vue from 'vue'

import Form1 from './Form_1.vue'
Vue.config.productionTip = false

new Vue({
  render: function(createElement) {
    const arg1 = this.$el.getAttribute('intoroduction')
    const type = this.$el.getAttribute('type')
    return createElement(Form1,
      {
        props: {
          introduction: arg1,
          id:"1",
          type:type
        }
      })
  }
}).$mount('.form_rady1')

new Vue({
  render: function(createElement) {
    const arg1 = this.$el.getAttribute('intoroduction')
    const type = this.$el.getAttribute('type')
    return createElement(Form1,
      {
        props: {
          introduction: arg1,
          id:"2",
          type:type
        }
      })
  }
}).$mount('.form_rady2')

