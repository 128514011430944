<template>
  <div class="form">
    <validation-observer ref="observer">
      <div class="form_container section_container">
        <div class="form_wrapper">
          <div class="wrapper_top">
            <p class="ttl pc">{{type=="gas"?'ガス':type=="elec"?'電気':''}}の契約代行相談フォーム</p>
            <p class="ttl sp">カンタンご入力フォーム</p>
          </div>
          <div  class="wrapper_bottom">
            <form action="">
              <p class="ttl">ClassLab専門オペレーターが<br class="sp">対応いたします！</p>

              <div class="input_top">

                <div class="consult_input">
                  <validation-provider name="姓" rules="required" v-slot="{ errors }">
                    <p class="error">{{ errors[0] }}</p>
                    <input v-model="formData.moveIn.LastName__c" class="text" name="last_name" type="text"
                      required="required" placeholder="姓を入力" />
                  </validation-provider>
                  <validation-provider name="名" rules="required" v-slot="{ errors }">
                    <p class="error">{{ errors[0] }}</p>
                    <input v-model="formData.moveIn.firstName__c" class="text" name="first_name" type="text"
                      required="required" placeholder="名を入力" />
                  </validation-provider>
                  <validation-provider name="電話番号" mode="eager" rules="required|phone" v-slot="{errors}">
                    <p class="error">{{ errors[0] }}</p>
                    <input v-model="formData.moveIn.MobilePhone__c" @input="numberFormat" class="text" name="mobile" type="text"
                      required="required" placeholder="電話番号を入力" />
                  </validation-provider>
                  <validation-provider name="メールアドレス" rules="email" v-slot="{errors}">
                    <p class="error">{{ errors[0] }}</p>
                    <input v-model="formData.moveIn.Email__c" class="text" inputmode="email" name="Email__c" type="text"
                      placeholder="メールアドレスを入力（任意）" />
                  </validation-provider>
                  <validation-provider name="住所" rules="required" v-slot="{errors}">
                    <p class="error">{{ errors[0] }}</p>
                    <textarea v-model="formData.moveIn.Street__c" name="Street__c" type="text"
                      placeholder="住所（物件名・部屋番号まで入れるとスムーズです）" />
                    </validation-provider>
                </div>
                
                <div v-if="type == 'gas'" class="checkbox_wrap">
                  <input v-model="formData.moveIn.WantGuideElec__c" :id="'elec'+id" name="WantGuideGas__c" type="checkbox" />
                  <label :for="'elec'+id">電気の契約も希望する</label>
                </div>
                <div v-if="type == 'elec'" class="checkbox_wrap">
                  <input v-model="formData.moveIn.WantGuideGas__c" :id="'gas'+id" name="WantGuideGas__c" type="checkbox" />
                  <label :for="'gas'+id">ガスの契約も希望する</label>
                </div>
                <div class="checkbox_wrap">
                  <input v-model="formData.moveIn.WantGuideWater__c" :id="'water'+id" type="checkbox" />
                   <label :for="'water'+id">水道の契約も希望する</label>

                </div>
              </div>
              <div  class="submit_btn">
                <!-- KF追記 -->
                <input class="submit" type="button" @click="send" value="送信">
              </div>
            </form>
            <p class="read">「<span><a class="policy" href="http://classlab.co.jp/privacy/">プライバシーポリシー</a></span>」をご一読、
              <br>ご理解いただき送信してください。</p>
            <p class="n"> ※営業時間外にご連絡いただいた場合は、<br class="sp">翌営業日に対応いたします。</p>
          </div>
        </div>
      </div>
    </validation-observer>
  </div>
</template>
<script>
  import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
  import axios from 'axios';
  import {
    ValidationProvider,
    ValidationObserver,
    extend,
    localize
  } from 'vee-validate'
  import * as rules from 'vee-validate/dist/rules'
  import ja from 'vee-validate/dist/locale/ja.json'
  import {
    required
  } from "vee-validate/dist/rules";
  Object.keys(rules).forEach((rule) => {
    extend(rule, rules[rule]) // eslint-disable-line
  })
  localize({
    ja
  });
  extend('phone', {
    // エラーメッセージを設定する
    message: 'ハイフン付きで正しく入力してください',
    validate(value) {
      const mobilePattern = /^0[5789]0/;
      if (mobilePattern.test(value) && value.length != 13) {
        return false;
      }
      const pattern = /^0\d{1,3}-\d{1,4}-\d{3,4}$/;
      return pattern.test(value);
    }
  });
  extend('email', {
    // エラーメッセージを設定する
    message: 'Emailの形式を確認してください',
    validate(value) {
      const pattern = /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
      return pattern.test(value);
    }
  });
  extend("required", {
    ...required,
    message: "{_field_}は必須です"
  });
  export default {
    components: {
      ValidationObserver: ValidationObserver,
      ValidationProvider: ValidationProvider,
    },
    props: {
    introduction: {
      type: String,
      required: true,
    } ,
    type: {
      type: String,
      required: true,
    } ,
    id: {
      type: String,
      required: true,
    } ,
  },
    methods: {
      numberFormat(){
        const region = 'JP';
        let util = PhoneNumberUtil.getInstance();
        console.log(this.formData.moveIn.MobilePhone__c);
        let number = null;
        try{
          number = util.parseAndKeepRawInput(this.formData.moveIn.MobilePhone__c, region);
        }catch(e){
          return;
        }
        
        console.log(number);
        if (!util.isValidNumberForRegion(number, region)) {
          return null;
        }
        this.formData.moveIn.MobilePhone__c = util.format(number, PhoneNumberFormat.NATIONAL);

      },
      async send() {
        const isValid = await this.$refs.observer.validate();
        if (isValid && !this.postbtn_disable) {
          this.postbtn_disable = true;
          let vm = this;
          axios
            .post(process.env.VUE_APP_API_URL + 'sendMoveIn', this.formData)
            .then(function (response) {
              if (response.data == 'ok') {
                vm.postbtn_disable = false;
                window.location = "completion.html"
                
              } else {
                window.alert("エラーが発生しました")
                vm.postbtn_disable = false;
              }
            })
            .catch(function () {
              vm.postbtn_disable = false;
            });
        } else {
          window.alert("フォームの入力項目をご確認ください")
        }
      },
    },
    created() {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      if (params.get('source')) {
        this.formData.moveIn.IntroductionFor__c = params.get('source');
      }
    },
    data() {
      return {
        step:1,
        
        postbtn_disable: false,
        formData: {
          moveIn: {
            IntroductionFor__c: this.introduction,
            WantGuideInternet__c: false,
            WantGuideGas__c: this.type == 'gas'? true :false,
            WantGuideWater__c: false,
            SMSRejection__c: true,
            ForLP__c: true,
            WantGuideElec__c: this.type == 'elec'? true :false,
            LastName__c: "",
            firstName__c: "",
            MobilePhone__c: "",
            Street__c: "",
            Email__c: "",
          }
        }
      }
    }
  }
</script>
<style lang="css">
  .prev_btn{
        background: linear-gradient(180deg, #a9a9a9 0%, #a9a9a9 30px, #949494 30px, #949494 100%);
            box-shadow: 0px 8px #7f7f7f;
  }
  .prev_btn::after{
        content:none;
  }
</style>